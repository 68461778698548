import React from "react";
import { Link } from "react-router-dom";

import logo from "../../assets/logo.png";

const WelcomePage = () => {
  const onAnswerHandler = () => {};

  return (
    <React.Fragment>
      <div className="d-grid text-center" style={{ height: "100%" }}>
        <div style={{ width: "100%" }}>
          <img className="mt-5" src={logo}></img>
        </div>

        <h1 className="mt-5 mb-5 text-center fw-bold" style={{ color: "#107869" }}>
          Frontizo welcomes you!
        </h1>

        <h3 className="m-2 fw-bold text-muted">
          Before we head to the home screen, let's answer a few quick questions.
        </h3>

        <Link to="/survey/home" style={{ textDecoration: "none" }}>
          <button className="d-grid btn-block mt-5 ms-3 me-3 mb-3 btn btn-lg text-light" style={{ backgroundColor: "#107869", width: "90%" }}>
            Let's Begin
          </button>
        </Link>
      </div>
    </React.Fragment>
  );
};

export default WelcomePage;
