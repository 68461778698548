import React from "react";

import { Card } from "reactstrap";
import { useNavigate } from "react-router-dom";

const Disclaimer = () => {
  const navigate = useNavigate();

  const onGoHomeHandler = (res) => {
    navigate("/");
  };

  const onGoContactHandler = (res) => {
    navigate("/menu");
  };

  return (
    <div style={{ position: "fixed", bottom: 0, width: "100%" }}>
      <div
        className="d-flex justify-content-center p-2"
        style={{
          width: "100%",
          border: "1px solid lightgrey",
          borderRadius: "5px 5px 0 0",
          backgroundColor: "#f4fcf2",
        }}
      >
        <button
          className="btn text-light btn-lg me-2"
          onClick={onGoHomeHandler}
          style={{ width: "30%", backgroundColor: "#107869" }}
        >
          Home
        </button>
        <button
          className="btn fw-bold text-light btn-lg"
          onClick={onGoContactHandler}
          style={{ width: "75%", background: "#f7b968" }}
        >
          <span>Contact Care Manager</span>
        </button>
      </div>

      {/* <p className="fw-light text-center text-muted">
          Please call 911 in case of an emergency.
        </p> */}
    </div>
  );
};

export default Disclaimer;
