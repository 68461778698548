import React, { useState } from "react";

//Third Party Libraries
import ReactHowler from "react-howler";
import { useLocation, useNavigate } from "react-router";
import { useMutation } from "@tanstack/react-query";

// Services
import * as api from "../services/patientService";
import { useEffect } from "react";

const CareAlertInstance = () => {
  const [playing, setPlay] = useState(false);
  const [ended, setEnd] = useState(false);
  const [player, setPlayer] = useState();
  const [duration, setDuration] = useState(0);
  const [seek, setSeek] = useState(0);

  const location = useLocation();
  const navigate = useNavigate();

  const alert = location.state.alert;
  const id = location.state.id;

  const { mutate: newAlert } = useMutation(api.addAlert);
  const { mutate: completeAlert } = useMutation(api.completeAlert);

  useEffect(() => {}, []);

  const onAnswerHandler = (answer) => {
    let toSend = {};

    toSend.patient = id;
    toSend.status = "notStarted";
    toSend.type = "response";
    toSend.response = answer;

    newAlert(toSend);
    completeAlert({ id: alert.id });

    navigate("/contacted");
  };

  const goHomeHandler = () => {
    completeAlert({ id: alert.id });
    navigate("/");
  };

  const setPlaying = () => {
    setPlay(!playing);
    setDuration(player.duration());
    startInterval();
  };

  const interval = null;

  const startInterval = () => {
    interval = setInterval(() => {
      // FIX THIS QUICKLY
      // console.log(player.seek());
      setSeek(Math.ceil(player.seek()));
    }, 1000);
  };

  const onEndHandler = () => {
    setPlay(false);
    setEnd(true);
    clearInterval(interval);
  };

  return (
    <React.Fragment>
      <div className="d-grid text-center" style={{ height: "100%" }}>
        <h1 className="m-5 text-center fw-light">{alert.message}</h1>
        <ReactHowler
          src={`https://3srubixv2184809-dev.s3.us-east-2.amazonaws.com/public/${alert.s3id}`}
          playing={playing}
          preload={true}
          onPause={() => setPlay(false)}
          onEnd={onEndHandler}
          ref={(ref) => setPlayer(ref)}
        />

        <button className="btn ms-5 me-5 mb-5" onClick={() => setPlaying()}>
          {playing ? (
            <i class="fa fa-pause fa-5x m-2"></i>
          ) : (
            <i class="fa fa-play fa-5x m-2"></i>
          )}
        </button>

        <h1 className="text-muted">
          {seek} / {Math.floor(duration)} s
        </h1>

        <p className="ms-5 me-5 mt-3 mb-3 fw-light text-muted">
          Click the play icon to hear what your care manager has to say. Answer
          yes or no below to your care managers question.
        </p>

        {alert.response_required == true ? (
          <>
            <button
              className="mt-2 ms-3 me-3 mb-3 btn btn-lg fw-bold text-light"
              style={{ backgroundColor: "#107869" }}
              disabled={!ended}
              onClick={() => onAnswerHandler("Patient Responded Yes")}
            >
              <h1>Yes</h1>
            </button>
            <button
              className="mt-2 ms-3 me-3 mb-3 btn btn-lg fw-bold text-light"
              style={{ backgroundColor: "#f7ab48" }}
              disabled={!ended}
              onClick={() => onAnswerHandler("Patient Responded No")}
            >
              <h1>No</h1>
            </button>
          </>
        ) : (
          <>
            <button
              className="mt-2 ms-3 me-3 mb-3 btn btn-lg fw-bold text-light"
              style={{ backgroundColor: "#107869" }}
              disabled={!ended}
              onClick={goHomeHandler}
            >
              <h1>I Understand</h1>
            </button>
            <button
              className="mt-2 ms-3 me-3 mb-3 btn btn-lg fw-bold text-light"
              style={{ backgroundColor: "#f7ab48" }}
              disabled={!ended}
              onClick={() =>
                onAnswerHandler(
                  "Patient needs more of an explanation on this care message."
                )
              }
            >
              <h1>I Need More Help</h1>
            </button>
          </>
        )}
      </div>
    </React.Fragment>
  );
};

export default CareAlertInstance;
