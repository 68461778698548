import React, { useState, useEffect } from "react";

// Third Party Libraries
import { useLocation, Link, useNavigate } from "react-router-dom";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  TabContent,
  TabPane,
} from "reactstrap";

// Themeing
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import {
  faUserDoctor,
  faExclamationCircle,
} from "@fortawesome/free-solid-svg-icons";

const CareAlert = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const patient = location.state.patient;

  const [alertList, setAlertList] = useState([]);
  const [completed, setCompleted] = useState([]);

  const [activeTab, setTabActive] = useState(1);

  useEffect(() => {
    let tmpList = [];
    let compList = [];

    for (let i = 0; i < patient.alerts.length; i++) {
      if (patient.alerts[i].status == "notStarted")
        tmpList.push(patient.alerts[i]);
      else compList.push(patient.alerts[i]);
    }

    setCompleted(compList);
    setAlertList(tmpList);
  }, [patient]);

  const statusSwitch = (status) => {
    switch (status) {
      case "notStarted":
        return "Not Completed";
      case "completed":
        return "Completed";
      default:
        break;
    }
  };

  const formatDate = (date) => {
    let formatted = new Date(date).toDateString();
    return formatted;
  };

  const goToAlertHandler = (alert) => {
    navigate("alertinstance", { state: { alert: alert, id: patient.id } });
  };

  return (
    <div className="p-2" style={{ width: "100%" }}>
      <h1 className="fw-bold mb-3">Alerts</h1>
      <Card className="">
        <div className="d-flex p-1" style={{ width: "100%" }}>
          <button
            className="btn fw-bold"
            style={{
              width: "50%",
              backgroundColor: `${activeTab === 1 ? "#107869" : "#ffffff"}`,
              color: `${activeTab === 1 ? "#ffffff" : "#000000"}`,
            }}
            onClick={() => setTabActive(1)}
          >
            Pending <span className=""></span>
          </button>
          <button
            className="btn fw-bold"
            onClick={() => setTabActive(2)}
            style={{
              width: "50%",
              backgroundColor: `${activeTab === 2 ? "#107869" : "#ffffff"}`,
              color: `${activeTab === 2 ? "#ffffff" : "#000000"}`,
            }}
          >
            Completed
          </button>
        </div>
      </Card>

      <h1 className="fw-light text-center mt-4 mb-3 ms-5 me-5">
        {alertList.length === 0 && activeTab === 1
          ? "No new notifications at this moment."
          : ""}
      </h1>

      <TabContent activeTab={activeTab}>
        <TabPane tabId={1}>
          <div style={{ overflowX: "auto", marginBottom: 100 }}>
            {alertList.map((ea) => (
              <Card
                className="mt-4"
                style={{ width: "100%" }}
                onClick={() => goToAlertHandler(ea)}
              >
                <CardHeader style={{ height: 50, backgroundColor: "#107869" }}>
                  <p
                    className="text-light fw-bold"
                    style={{ position: "relative", top: 5 }}
                  >
                    <FontAwesomeIcon
                      className="text-light me-3"
                      icon={faClock}
                    />
                    {formatDate(ea.created_at)}
                  </p>
                </CardHeader>
                <CardBody>
                  <h5 className="fw-bold" style={{ color: "#f7ab48" }}>
                    <FontAwesomeIcon
                      className="me-2"
                      icon={faExclamationCircle}
                    />
                    {statusSwitch(ea.status)}
                  </h5>

                  <p className="fw-light">{ea.message}</p>

                  <p className="" style={{ color: "#f7ab48" }}>
                    Tap to View
                  </p>
                </CardBody>
              </Card>
            ))}
          </div>
        </TabPane>

        <TabPane tabId={2}>
          <div style={{ overflowX: "auto", marginBottom: 100 }}>
            {completed.map((ea) => (
              <Card
                className="mt-4"
                style={{ width: "100%" }}
                onClick={() => goToAlertHandler(ea)}
              >
                <CardHeader style={{ height: 50, backgroundColor: "#107869" }}>
                  <p
                    className="text-light fw-bold"
                    style={{ position: "relative", top: 5 }}
                  >
                    <FontAwesomeIcon
                      className="text-light me-3"
                      icon={faClock}
                    />
                    {formatDate(ea.created_at)}
                  </p>
                </CardHeader>
                <CardBody>
                  <h5 className="fw-bold" style={{ color: "#107869" }}>
                    <FontAwesomeIcon
                      className="me-2"
                      style={{ color: "#107869" }}
                      icon={faExclamationCircle}
                    />
                    {statusSwitch(ea.status)}
                  </h5>

                  <p className="fw-light">{ea.message}</p>

                  <p className="" style={{ color: "#f7ab48" }}>Tap to View</p>
                </CardBody>
              </Card>
            ))}
          </div>
        </TabPane>
      </TabContent>
    </div>
  );
};

export default CareAlert;
