import React from "react";

const CareContacted = () => {
  return (
    <React.Fragment>
      <div className="d-grid p-2" style={{ height: "100%" }}>
        <div>
          <h1 className="fw-bold text-center mt-5">
            Your Care Manager has been notified; Natalie R.N. will call you
            on this phone.
          </h1>
          <h3 className="fw-light text-center text-danger mt-5">
            Call 911 if you have an emergency.
          </h3>
        </div>

        
      </div>
    </React.Fragment>
  );
};

export default CareContacted;
