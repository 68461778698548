import axios from "axios";

import { Auth } from "aws-amplify";

const api = axios.create({
  baseURL: process.env.REACT_APP_API,
});

export const getPatient = (params) =>
  api
    .post("patient/instance", 
      params,
    )
    .then((res) => res.data);

export const addAlert = (params) =>
  api.put("alert", params).then((res) => res.data);

export const getAlerts = (params) =>
  api.post("alert", params).then((res) => res.data);

export const completeAlert = (params) =>
  api.post("completecarealert", params).then((res) => res.data);

export const completeSurvey = (params) =>
  api.post("homesurvey", params).then((res) => res.data);
