import React from "react";
import { useState } from "react";

// Third Party Libraries
import { useQuery } from "@tanstack/react-query";

// Components
import MedicationPage from "./medicationsPage";

// APIs
import * as api from "../services/patientService";
import HomePage from "./homePage";
import WelcomePage from "./homeSurvey/welcomePage";

// Assets
import logo from "../assets/logo.png";

const BasePage = ({ user }) => {
  const [isHome, setHome] = useState(true);

  const { data, isLoading } = useQuery(
    ["patient"],
    () => api.getPatient({ id: user.attributes["custom:id"] }),
    {
      onSuccess: (data) => {
        setHome(data.home_survey);
      },
    }
  );

  return (
    <div className="mb-5" style={{ width: "100%", height: "100%" }}>
      {isLoading === false ? (
        <React.Fragment>
          {isHome === true ? <HomePage data={data} /> : <WelcomePage />}
        </React.Fragment>
      ) : (
        <div className="" style={{ width: "100%", height: "100%" }}>
          <div
            className="d-grid d-grid justify-content-center"
            style={{ marginTop: "50%" }}
          >
            <img src={logo} />
            <div className="d-grid justify-content-center">
              <div class="spinner-border mt-5 text-muted" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default BasePage;
