import React from "react";
import "./App.css";

// Third Party Libraries
import { Route, Routes } from "react-router-dom";

// Amplify
import { withAuthenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";

// Components
import NavBar from "./components/navBar";
import BasePage from "./components/basePage";
import ContactMenu from "./components/contactMenu";
import Disclaimer from "./components/disclaimer";
import ApptsPage from "./components/apptsPage";
import CareContacted from "./components/contactOptions/careContacted";
import FeelSick from "./components/feelSick";
import CareAlert from "./components/careAlert";
import CareAlertInstance from "./components/careAlertInstance";
import AtHomeQuestion from "./components/homeSurvey/atHomeQuestion";
import MedicationQuestion from "./components/homeSurvey/medicationQuestion";
import FinishedSurvey from "./components/homeSurvey/finishedSurvey";
import MedicationPage from "./components/medicationsPage";
import CarePlan from "./components/carePlan";

function App({ signOut, user }) {
  return (
    <div className="App ms-2 me-2" style={{ height: "100%", marginBottom: 100 }}>
      <NavBar user={user} signOut={signOut} />
      <div></div>
      <div
        className="d-flex align-items-center flex-column"
        style={{ marginBottom: 100 }}
      >
        <Routes>
          <Route path="/*" element={<BasePage user={user} />} />
          <Route path="/home" element={<BasePage />} />
          <Route path="/menu" element={<ContactMenu />} />
          <Route path="/appts" element={<ApptsPage />} />
          <Route path="/careplan" element={<CarePlan />} />
          <Route path="/meds" element={<MedicationPage />} />
          <Route path="/contacted" element={<CareContacted />} />
          <Route path="/feelsick" element={<FeelSick />} />
          <Route path="/carealert" element={<CareAlert />} />
          <Route path="/carealert/alertinstance" element={<CareAlertInstance />} />
          <Route path="/survey/home" element={<AtHomeQuestion />} />
          <Route path="/survey/meds" element={<MedicationQuestion />} />
          <Route path="/survey/finish" element={<FinishedSurvey />} />
        </Routes>

        <Disclaimer />
      </div>
    </div>
  );
}

export default withAuthenticator(App);
