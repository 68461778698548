import React from "react";
import { Nav, Navbar, NavbarBrand, NavItem } from "reactstrap";

import logo from "../assets/logo.png";

const NavBar = ({ signOut, user }) => {
  return (
    <React.Fragment>
      <Navbar>
        <NavbarBrand href="/">
          <img src={logo} width="115" />
        </NavbarBrand>
        <Nav>
          <NavItem onClick={signOut}>
            <i class="fa fa-sign-out m-2 text-danger"></i>
          </NavItem>
        </Nav>
      </Navbar>
    </React.Fragment>
  );
};

export default NavBar;
