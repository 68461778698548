import React, { useState } from "react";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack5";

//import pdf from "../assets/patientcareplan.pdf";
import carePlan from "../assets/plan.png";
import carePlan2 from "../assets/plan2.png";

const CarePlan = () => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  return (
    <div style={{}}>
      <h1 className="fw-bold mb-3 ms-2 mt-1">Care Plan</h1>
      <p className="p-2">
        The Patient Care Plan is designed to give you, the patient, a convenient
        bird's eye view of your healthcare relating to your recent hospital
        stay, recent stay at the Skilled Nursing Facility, and your health in
        the post-discharge period after your stay at the SNF. You are encouraged
        to contact your healthcare providers directly to obtain a complete
        picture of your health. All of the information contained in this care
        plan is subject to change. The information is not to be construed as
        medical advice, diagnosis, or treatment.{" "}
        <span className="text-danger">Call 911 if you have an emergency</span>
      </p>
      <img src={carePlan} width="100%" height="512px"></img>
      <img src={carePlan2} width="100%" height="256px"></img>
      {/* <Document file="https://3srubixv2184809-dev.s3.us-east-2.amazonaws.com/public/patientcareplan.pdf" onLoadSuccess={onDocumentLoadSuccess}>
        <Page width={400} height={400} scale={1} pageNumber={pageNumber} />
      </Document> */}
    </div>
  );
};

export default CarePlan;
