import React from "react";

// Third Party Libraries
import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { Auth } from "aws-amplify";

// Assets
import logo from "../../assets/logo.png";

// Services
import * as api from "../../services/patientService";

const FinishedSurvey = () => {
  const navigate = useNavigate();

  const onAnswerHandler = (res) => {
    navigate("/");
  };

  const goToContact = (res) => {
    navigate("/menu");
  };

  const { data, isLoading } = useQuery(
    ["patient"],
    () => api.getPatient({ id: Auth.user.attributes["custom:id"] }),
    {
      onSuccess: (data) => {
      },
    }
  );

  return (
    <React.Fragment>
      <div className="d-grid text-center" style={{ height: "100%" }}>
        <div style={{ width: "100%" }}>
          <img className="mt-2" src={logo}></img>
        </div>
        <div className="" style={{ marginTop: 20 }}>
          <h1 className="text-center fw-bold">{data.managed_by.first_name} {data.managed_by.last_name}, RN</h1>
          <h3 className="mb-4 text-center fw-light">
            Your Personal Care Manager
          </h3>

          <h1 className="text-center fw-bold">{data.facility.name}</h1>
          <h3 className="text-center fw-light">Discharge Facility</h3>
        </div>
        <button
          className="mt-5 ms-3 me-3 mb-3 btn btn-lg text-light"
          style={{ backgroundColor: "#107869" }}
          onClick={() => goToContact()}
        >
          <h1>Contact Care Manager</h1>
        </button>
        <button
          className="mt-2 ms-3 me-3 mb-3 btn btn-lg text-light"
          style={{ backgroundColor: "#107869" }}
          onClick={() => onAnswerHandler("Patient Responded No")}
        >
          <h1>Done</h1>
        </button>

        <h5 className="text-danger">Call 911 if you have an emergency</h5>
      </div>
    </React.Fragment>
  );
};

export default FinishedSurvey;
