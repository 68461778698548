import React, { useState, useEffect } from "react";

// Third Party Libraries
import { Container } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendar,
  faMedkit,
  faClipboardList,
} from "@fortawesome/free-solid-svg-icons";

const HomePage = ({ data }) => {
  let today = new Date();
  let dcDate = new Date(data.dc_date);

  let daySince = (today.getTime() - dcDate.getTime()) / (1000 * 3600 * 24);

  const [alertCnt, setAlertCnt] = useState(0);
  const [latestAlert, setLatest] = useState([]);
  const [alertInProgress, setAlertInProgress] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    let tmpList = [];
    let notifs = [];

    for (let i = 0; i < data.alerts.length; i++) {
      if (data.alerts[i].status == "notStarted") tmpList.push(data.alerts[i]);
    }

    setAlertCnt(tmpList.length);

    for (let i = 0; i < data.notifs.length; i++) {
      if (
        data.notifs[i].status == "notStarted" &&
        data.notifs[i].type !== "response" &&
        data.notifs[i].type !== "homesurvey"
      )
        notifs.push(data.notifs[i]);
    }

    setAlertInProgress(true);
    setLatest(notifs);
  }, [data]);

  const typeSwitch = (key) => {
    switch (key) {
      case "med_help":
        return "Medication Help";
      case "appt_help":
        return "Appointment Help";
      case "feelsick":
        return "I Feel Sick";
      case "homesurvey":
        return "Home Survey";
      case "response":
        return "Response";
      case "other_med":
        return "Medication Help";
      case "other_appt":
        return "Appointment Help";
      default:
        break;
    }
  };

  const goToAppointmentsHandler = () => {
    navigate("/appts", { state: { patient: data } });
  };
  const goToMedicationsHandler = () => {
    navigate("/meds", { state: { patient: data } });
  };

  const goToCareAlertsHandler = () => {
    navigate("/carealert", { state: { patient: data } });
  };

  const goToCarePlanHandler = () => {
    navigate("/careplan", { state: { patient: data } });
  };

  const formatDate = (date) => {
    let formatted = new Date(date).toUTCString();
    return formatted;
  };

  return (
    <React.Fragment>
      <Container className="mt-3" fluid style={{}}>
        <h1
          className="display-1 text-center fw-bold"
          style={{ color: "#107869" }}
        >
          {Math.round(daySince)}
        </h1>
        <h1 className="text-center"> Days since SNF Discharge</h1>
      </Container>

      <Container className="" style={{}}>
        <div className="d-flex mt-4">
          <div
            className="p-3 d-grid justify-content-center"
            style={{
              border: "1px solid lightgrey",
              borderRadius: "10px",
              backgroundColor: "#107869",
              width: "50%",
              height: 128,
            }}
            onClick={goToAppointmentsHandler}
          >
            <FontAwesomeIcon
              icon={faCalendar}
              className="text-light fa-2x"
              style={{ position: "relative", backgroundColor: "" }}
            />
            <h5 className="mt-3 text-center fw-bold text-light">
              Appointments
            </h5>
          </div>

          <div
            className="ms-2 p-3 d-grid justify-content-center"
            style={{
              border: "1px solid lightgrey",
              borderRadius: "10px",
              backgroundColor: "#107869",
              width: "50%",
              height: 128,
            }}
            onClick={goToMedicationsHandler}
          >
            <FontAwesomeIcon
              icon={faMedkit}
              className="text-light fa-2x"
              style={{ position: "relative" }}
            />
            <h5 className="mt-3 text-center fw-bold text-light">Medications</h5>
          </div>
        </div>

        <div className="d-flex mt-2">
          <div
            className="p-3 d-grid justify-content-center"
            style={{
              border: "1px solid lightgrey",
              borderRadius: "10px",
              backgroundColor: "#f7ab48",
              width: "50%",
              height: 128,
            }}
            onClick={goToCareAlertsHandler}
          >
            <h5 className="display-5 fw-bold text-light">{alertCnt}</h5>
            <h5 className="mt-1 fw-bold text-light" style={{ width: "110%" }}>
              Pending Alerts
            </h5>
          </div>

          <div
            className="ms-2 p-3 d-grid justify-content-center"
            style={{
              border: "1px solid lightgrey",
              borderRadius: "10px",
              backgroundColor: "#107869",
              width: "50%",
              height: 128,
            }}
            onClick={goToCarePlanHandler}
          >
            <FontAwesomeIcon
              icon={faClipboardList}
              className="text-light fa-2x"
              style={{ position: "relative" }}
            />
            <h5 className="mt-3 text-center fw-bold text-light">Care Plan</h5>
          </div>
        </div>
      </Container>

      <Container>
        {latestAlert.map((ea) => (
          <div
            className="mt-3 p-1"
            style={{
              width: "100%",
              border: "1px solid lightgrey",
              borderRadius: "10px",
              backgroundColor: "#f4fcf2",
            }}
          >
            <p
              className="fw-light ms-2"
              style={{ position: "relative", top: 10 }}
            >
              Sent Request:{" "}
              <span className="fw-bold">{typeSwitch(ea.type)} <br/> {formatDate(ea.created_at)}</span>
            </p>
          </div>
        ))}
      </Container>
    </React.Fragment>
  );
};

export default HomePage;
