import React from "react";

import { useNavigate } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import * as api from "../../services/patientService";

import logo from "../../assets/logo.png";
import { Auth } from "aws-amplify";

const MedicationQuestion = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { mutate: newAlert } = useMutation(api.addAlert);
  const { mutate: completeSurvey } = useMutation(api.completeSurvey);

  console.log(Auth.user.attributes["custom:id"]);

  const onAnswerHandler = (res) => {
    let toSend = {};

    toSend.patient = Auth.user.attributes["custom:id"];
    toSend.type = "homesurvey";
    toSend.response = res;

    completeSurvey({ id: Auth.user.attributes["custom:id"] });
    newAlert(toSend);

    queryClient.invalidateQueries("patient");

    navigate("/survey/finish");
  };

  return (
    <React.Fragment>
      <div className="d-grid text-center" style={{ height: "100%" }}>
        <div style={{ width: "100%" }}>
          <img className="mt-5" src={logo}></img>
        </div>

        <h1 className="m-5 text-center fw-bold" style={{ color: "#107869" }}>
          Have you picked up your medications?
        </h1>

        <button
          className="mt-2 ms-3 me-3 mb-3 btn btn-lg text-light"
          style={{ backgroundColor: "#107869" }}
          onClick={() =>
            onAnswerHandler(
              "Patient has picked up their meds and arrvied home safely."
            )
          }
        >
          <h1>Yes</h1>
        </button>
        <button
          className="mt-2 ms-3 me-3 mb-3 btn btn-lg text-light"
          style={{ backgroundColor: "#f7b968" }}
          onClick={() =>
            onAnswerHandler(
              "Patient has not picked up their meds, but arrived home safely."
            )
          }
        >
          <h1>No</h1>
        </button>
      </div>
    </React.Fragment>
  );
};

export default MedicationQuestion;
