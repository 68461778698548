import React from "react";
import { useNavigate } from "react-router-dom";

import logo from "../../assets/logo.png";

const AtHomeQuestion = () => {
  const navigate = useNavigate();

  const onAnswerHandler = (res) => {
    navigate("/survey/meds", { data: res });
  };

  return (
    <React.Fragment>
      <div className="d-grid text-center" style={{ height: "100%" }}>
        <div style={{ width: "100%" }}>
          <img className="mt-5" src={logo}></img>
        </div>

        <h1 className="m-5 text-center fw-bold" style={{ color: "#107869" }}>
          Have you arrived home safely?
        </h1>

        <button
          className="mt-2 ms-3 me-3 mb-3 btn text-light btn-lg"
          style={{ backgroundColor: "#107869" }}
          onClick={() => onAnswerHandler("Patient Responded Yes")}
        >
          <h1>Yes</h1>
        </button>
        <button
          className="mt-2 ms-3 me-3 mb-3 btn btn-lg text-white"
          style={{ backgroundColor: "#f7b968" }}
          onClick={() => onAnswerHandler("Patient Responded No")}
        >
          <h1>No</h1>
        </button>
      </div>
    </React.Fragment>
  );
};

export default AtHomeQuestion;
