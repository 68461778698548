import React from "react";

// Third Party Libraries
import { Link, useLocation } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { Auth } from "aws-amplify";

// APIs
import * as api from "../services/patientService";

const ContactMenu = () => {

  const { mutate: newAlert } = useMutation(api.addAlert);

  const addAlertHandler = (from) => {
    let toSend = {};

    toSend.patient = Auth.user.attributes["custom:id"];
    toSend.status = "notStarted";
    toSend.type = from;
    toSend.response = "I need help";

    let date = new Date();
    toSend.created_at = date;

    newAlert(toSend);
  };

  return (
    <React.Fragment>
      <div className="d-grid p-2" style={{ height: "100%" }}>
        <h2 className="mt-5 fw-bold text-center">
          Click on the option that best describes what you need
        </h2>

        <div>
          <Link to="/feelsick" style={{ textDecoration: "none" }}>
            <div className="d-grid mt-5">
              <button className="btn btn-lg fw-bold text-light btn-block" style={{ backgroundColor: "#f7ab48" }}>
                I Feel Sick
              </button>
            </div>
          </Link>

          <Link
            to="/contacted"
            state={{ from: "med_help" }}
            style={{ textDecoration: "none" }}
          >
            <div className="d-grid mt-3">
              <button
                className="btn btn-lg fw-bold text-light btn-block"
                style={{ backgroundColor: "#107869" }}
                onClick={() => addAlertHandler("med_help")}
              >
                Medication Help
              </button>
            </div>
          </Link>

          <Link
            to="/contacted"
            state={{ from: "appt_help" }}
            style={{ textDecoration: "none" }}
          >
            <div className="d-grid mt-3">
              <button
                className="btn btn-lg fw-bold text-light btn-block"
                style={{ backgroundColor: "#107869" }}
                onClick={() => addAlertHandler("appt_help")}
              >
                Appointment Help
              </button>
            </div>
          </Link>
        </div>
        
      </div>
    </React.Fragment>
  );
};

export default ContactMenu;
