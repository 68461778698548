import React from "react";

import { useMutation } from "@tanstack/react-query";

import * as api from "../services/patientService";
import { useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";

const FeelSick = () => {
  const { mutate: newAlert, isSuccess } = useMutation(api.addAlert);
  const navigate = useNavigate();

  const addAlertHandler = (res) => {
    let toSend = {};

    toSend.patient = Auth.user.attributes["custom:id"];
    toSend.status = "notStarted";
    toSend.type = "feelsick";
    toSend.response = res;

    newAlert(toSend);
    navigate("/contacted");
  };

  return (
    <React.Fragment>
      <div className="mt-5">
        <h2 className="fw-bold m-5 text-center">
          Click on the symptom that closest represents how you are feeling
        </h2>
        <div className="d-grid mt-5">
          <button
            className="m-2 btn btn-lg fw-bold"
            style={{ border: "1px solid #f7ab48", color: "#107869" }}
            onClick={() => addAlertHandler("Weakness / Fatigue")}
          >
            Weakness / Fatigue
          </button>
          <button
            className="m-2 btn btn-lg fw-bold"
            style={{ border: "1px solid #f7ab48", color: "#107869" }}
            onClick={() => addAlertHandler("Worsening of Existing Condition")}
          >
            Worsening of Existing Condition
          </button>
          <button
            className="m-2 btn btn-lg fw-bold"
            style={{ border: "1px solid #f7ab48", color: "#107869" }}
            onClick={() => addAlertHandler("New Symptom")}
          >
            New Symptom
          </button>
        </div>
      </div>
    </React.Fragment>
  );
};

export default FeelSick;
