import React, { useState, useEffect } from "react";

// Third Party Libraries
import { useLocation, useNavigate } from "react-router-dom";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  TabContent,
  TabPane,
} from "reactstrap";
import { useMutation } from "@tanstack/react-query";

// APIs
import * as api from "../services/patientService";

// Themeing
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import { faUserDoctor, faLocationDot } from "@fortawesome/free-solid-svg-icons";

const ApptsPage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [activeTab, setTabActive] = useState(1);
  const [upcoming, setUpcoming] = useState([]);
  const [past, setPast] = useState([]);

  const { state } = location;
  const { patient } = state;
  const visits = patient.visits;

  const { mutate: newAlert } = useMutation(api.addAlert);

  useEffect(() => {
    const today = new Date();

    const filterByUpcoming = (arr) =>
      arr.filter(({ date }) => new Date(date.replace(/-/g, "/")) >= today);
    setUpcoming(filterByUpcoming(visits));

    // const filterByTime = (arr) =>
    //   arr.filter(({ time }) => {
    //     let visitHours = Number(time.substring(0, 2));
    //     let visitMinutes = Number(time.substring(3, 5));
    //     let visitMeridian = time.substring(6, 8);

    //     if (visitMeridian == "PM") visitHours += 12;

    //     if (today.getHours() <= visitHours) return time;

    //     console.log(today.getHours(), today.getMinutes());
    //     console.log(visitHours, visitMinutes, visitMeridian);
    //   });

    // console.log(filterByTime(upcoming));

    const filterByPast = (arr) =>
      arr.filter(({ date }) => new Date(date.replace(/-/g, "/")) < today);
    setPast(filterByPast(visits));
  }, [state]);

  const addAlertHandler = (res) => {
    let toSend = {};

    toSend.patient = patient.id;
    toSend.status = "notStarted";
    toSend.type = "other_appt";
    toSend.response = res;

    console.log(toSend);

    newAlert(toSend);
    navigate("/contacted");
  };

  const formatDate = (date) => {
    let formatted = new Date(date).toUTCString().substring(0, 16);
    return formatted;
  };

  return (
    <div className="p-2" style={{ width: "100%" }}>
      <h1 className="fw-bold mb-3">Appointments</h1>

      <p>
        Please review your appointments and verify that the list is up to date.{" "}
        <span className="text-danger">Call 911 if you have an emergency</span>
      </p>

      <Card className="">
        <div className="d-flex p-1" style={{ width: "100%" }}>
          <button
            className="btn fw-bold"
            style={{
              width: "50%",
              backgroundColor: `${activeTab === 1 ? "#107869" : "#ffffff"}`,
              color: `${activeTab === 1 ? "#ffffff" : "#000000"}`,
            }}
            onClick={() => setTabActive(1)}
          >
            Upcoming
          </button>
          <button
            className="btn fw-bold"
            onClick={() => setTabActive(2)}
            style={{
              width: "50%",
              backgroundColor: `${activeTab === 2 ? "#107869" : "#ffffff"}`,
              color: `${activeTab === 2 ? "#ffffff" : "#000000"}`,
            }}
          >
            Past Appts
          </button>
        </div>
      </Card>

      <TabContent activeTab={activeTab}>
        <TabPane tabId={1}>
          <div>
            {upcoming.map((visit) => (
              <div className="mb-4" style={{ width: "100%" }}>
                <Card className="mt-3">
                  <CardHeader
                    className=""
                    style={{ height: 60, backgroundColor: "#107869" }}
                  >
                    <h5
                      className="text-light fw-bold"
                      style={{ position: "relative", top: 10 }}
                    >
                      <FontAwesomeIcon
                        className="text-light me-2"
                        icon={faClock}
                      />
                      {formatDate(visit.date)} {visit.time}
                    </h5>
                  </CardHeader>
                  <CardBody style={{ backgroundColor: "#f4fcf2" }}>
                    <h5>
                      <FontAwesomeIcon className="me-2" icon={faUserDoctor} />
                      {visit.provider}
                    </h5>
                    <div className="mt-1">
                      <p
                        className="fw-bold"
                        style={{ position: "relative", top: 20 }}
                      >
                        <FontAwesomeIcon
                          className="me-2"
                          icon={faLocationDot}
                        />
                        {visit.facility}
                      </p>
                      <p className="fw-light">
                        {visit.address}, {visit.city} {visit.state}, {visit.zip}
                      </p>
                    </div>
                    <h5 className="fw-light">{visit.reason}</h5>
                  </CardBody>
                  <CardFooter className="" style={{ backgroundColor: "white" }}>
                    <div className="d-flex">
                      <button
                        className="btn fw-bold me-2 btn-sm"
                        style={{
                          color: "#f7ab48",
                          border: "1px solid #f7ab48",
                        }}
                        onClick={() =>
                          addAlertHandler(
                            `Would like to Reschedule Appointment on ${visit.date} with ${visit.provider}`
                          )
                        }
                      >
                        Reschedule
                      </button>
                      <button
                        className="btn fw-bold me-2 btn-sm"
                        style={{
                          color: "#f7ab48",
                          border: "1px solid #f7ab48",
                        }}
                        onClick={() =>
                          addAlertHandler(
                            `Don't have Ride to Appointment on ${visit.date} with ${visit.provider}`
                          )
                        }
                      >
                        Ride Help
                      </button>
                      <button
                        className="btn fw-bold btn-sm"
                        style={{
                          color: "#f7ab48",
                          border: "1px solid #f7ab48",
                        }}
                        onClick={() =>
                          addAlertHandler(
                            `Need general help with Appointment on ${visit.date} with ${visit.provider}`
                          )
                        }
                      >
                        General Help
                      </button>
                    </div>
                  </CardFooter>
                </Card>
              </div>
            ))}
          </div>
        </TabPane>

        <TabPane tabId={2}>
          <div>
            {past.map((visit) => (
              <div className="mb-4" style={{ width: "100%" }}>
                <Card className="mt-3">
                  <CardHeader
                    className=""
                    style={{ height: 60, backgroundColor: "#107869" }}
                  >
                    <h5
                      className="text-light fw-bold"
                      style={{ position: "relative", top: 10 }}
                    >
                      <FontAwesomeIcon
                        className="text-light me-2"
                        icon={faClock}
                      />
                      {formatDate(visit.date)} {visit.time}
                    </h5>
                  </CardHeader>
                  <CardBody style={{ backgroundColor: "#f4fcf2" }}>
                    <h5>
                      <FontAwesomeIcon className="me-2" icon={faUserDoctor} />
                      {visit.provider}
                    </h5>
                    <div className="mt-1">
                      <p
                        className="fw-bold"
                        style={{ position: "relative", top: 20 }}
                      >
                        <FontAwesomeIcon
                          className="me-2"
                          icon={faLocationDot}
                        />
                        {visit.facility}
                      </p>
                      <p className="fw-light">
                        {visit.address}, {visit.city} {visit.state}, {visit.zip}
                      </p>
                    </div>
                    <h5 className="fw-light">{visit.reason}</h5>
                  </CardBody>
                  <CardFooter className="" style={{ backgroundColor: "white" }}>
                    <div className="d-flex">
                      <button
                        className="btn fw-bold me-2 btn-sm"
                        style={{
                          color: "#f7ab48",
                          border: "1px solid #f7ab48",
                        }}
                        onClick={() =>
                          addAlertHandler(
                            `Would like to Reschedule Appointment on ${visit.date} with ${visit.provider}`
                          )
                        }
                      >
                        Reschedule
                      </button>
                      <button
                        className="btn fw-bold me-2 btn-sm"
                        style={{
                          color: "#f7ab48",
                          border: "1px solid #f7ab48",
                        }}
                        onClick={() =>
                          addAlertHandler(
                            `Don't have Ride to Appointment on ${visit.date} with ${visit.provider}`
                          )
                        }
                      >
                        Ride Help
                      </button>
                      <button
                        className="btn fw-bold btn-sm"
                        style={{
                          color: "#f7ab48",
                          border: "1px solid #f7ab48",
                        }}
                        onClick={() =>
                          addAlertHandler(
                            `Need general help with Appointment on ${visit.date} with ${visit.provider}`
                          )
                        }
                      >
                        General Help
                      </button>
                    </div>
                  </CardFooter>
                </Card>
              </div>
            ))}
          </div>
        </TabPane>
        <button
          className="d-grid btn btn-block mt-2 fw-bold text-center"
          style={{
            color: "#f7ab48",
            border: "1px solid #f7ab48",
            width: "100%",
          }}
          onClick={() =>
            addAlertHandler(`Patient wants to report a Appointment`)
          }
        >
          Report an Appointment
        </button>
      </TabContent>
    </div>
  );
};

export default ApptsPage;
