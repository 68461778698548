import React from "react";

import { Card, CardBody, CardHeader, CardFooter } from "reactstrap";
import { useMutation } from "@tanstack/react-query";
import { useNavigate, useLocation } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserDoctor, faMedkit } from "@fortawesome/free-solid-svg-icons";

// APIs
import * as api from "../services/patientService";

const MedicationPage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { state } = location;
  const { patient } = state;
  const meds = patient.meds;

  const { mutate: newAlert } = useMutation(api.addAlert);

  const addAlertHandler = (res) => {
    let toSend = {};

    toSend.patient = patient.id;
    toSend.status = "notStarted";
    toSend.type = "other_med";
    toSend.response = res;

    newAlert(toSend);
    navigate("/contacted");
  };

  return (
    <div className="p-2" style={{ width: "100%" }}>
      <h1 className="fw-bold mb-3">Current Medications</h1>
      <p>
        Please review your medications and verify that the list is up to date.{" "}
        <span className="text-danger">Call 911 if you have an emergency</span>
      </p>

      <div>
        {meds.map((med) => (
          <div className="mb-4" style={{ width: "100%" }}>
            <Card className="mt-3">
              <CardHeader
                className="d-flex"
                style={{ height: 60, backgroundColor: "#107869" }}
              >
                <h3
                  className="text-light fw-bold"
                  style={{ position: "relative", top: 10 }}
                >
                  <FontAwesomeIcon
                    className="text-light me-3"
                    icon={faMedkit}
                  />
                  {med.name}
                </h3>

                <h4
                  className="text-light fw-bold ms-auto"
                  style={{ position: "relative", top: 10 }}
                >
                  {med.strength}
                </h4>
              </CardHeader>
              <CardBody style={{ backgroundColor: "#f4fcf2" }}>
                <h5 className="fw-light">{med.instructions}</h5>

                {/* <div className="">
                  <p
                    className="fw-bold"
                    style={{ position: "relative", top: 20 }}
                  >
                    Drug Class: {med.drugclass}
                  </p>
                </div> */}

                <p>
                  <FontAwesomeIcon className="me-2 mt-3" icon={faUserDoctor} />
                  Prescribed by: {med.provider}
                </p>
                <h5 className="fw-light">{}</h5>
              </CardBody>
              <CardFooter className="" style={{ backgroundColor: "white" }}>
                <div className="d-flex">
                  <button
                    className="btn fw-bold me-2 btn-sm"
                    style={{ color: "#f7ab48", border: "1px solid #f7ab48" }}
                    onClick={() =>
                      addAlertHandler(
                        `Can't pick up medication ${med.name} prescribed by ${med.provider}`
                      )
                    }
                  >
                    Can't Pickup
                  </button>
                  <button
                    className="btn fw-bold me-2 btn-sm"
                    style={{ color: "#f7ab48", border: "1px solid #f7ab48" }}
                    onClick={() =>
                      addAlertHandler(
                        `Not taking medication ${med.name} prescribed by ${med.provider}`
                      )
                    }
                  >
                    Not Taking
                  </button>
                  <button
                    className="btn fw-bold btn-sm"
                    style={{ color: "#f7ab48", border: "1px solid #f7ab48" }}
                    onClick={() =>
                      addAlertHandler(
                        `Need general help with medication ${med.name} prescribed by ${med.provider}`
                      )
                    }
                  >
                    General Help
                  </button>
                </div>
              </CardFooter>
            </Card>
          </div>
        ))}

        <button
          className="d-grid btn btn-block mt-2 fw-bold text-center"
          style={{
            color: "#f7ab48",
            border: "1px solid #f7ab48",
            width: "100%"
          }}
          onClick={() =>
            addAlertHandler(`Patient wants to report a Medication`)
          }
        >
          Report a Medication
        </button>
      </div>
    </div>
  );
};

export default MedicationPage;
